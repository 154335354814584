import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Constant } from 'config/constant';
import { RootState } from 'redux/store';
import { getErrorMessage } from 'api';
import {
    createNotification,
    deleteNotification,
    getListNotification,
    getDetailNotification,
    updateNotification, pushNotification
} from 'api/notification';
import { fetchListCategory } from './categorySlice';
import { CreateNotificationData, DetailNotificationData, NotificationData, RegisterNotificationData } from 'types';
import { getCategoryList } from 'api/category';
import { getSearchPlaceInListCharger } from 'api/charger';

export type CategoryData = {
    id: number;
    name: string;
};

export type PlaceData = {
    id: number;
    name: string;
    address: string;
};

export type SearchPlaceNotificationRequest = {
    keyword?: string;
};

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type ListNotification = {
    error: boolean;
    loading: boolean;
    success: boolean;
    notification: NotificationData[];
    pagination: Pagination;
    status: string;
};

export type NotificationState = {
    status: number;
    notificationData: NotificationData[];
    listNotification: ListNotification;
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | RegisterNotificationData;
    };
    update: {
        success: boolean;
        error: boolean;
        messages: string | RegisterNotificationData;
        loading: boolean;
    };
    delete: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    pushNotification: {
        success: boolean;
        error: boolean;
        messages: string | RegisterNotificationData;
        loading: boolean;
    };
    detail: {
        success: boolean;
        error: boolean;
        messages: string | RegisterNotificationData;
        loading: boolean;
        dataDetail: DetailNotificationData;
        pushAvailable: boolean;
    };
    listCategory: {
        error: boolean;
        loading: boolean;
        success: boolean;
        categories: CategoryData[];
    };
    listSearchPlace: {
        error: boolean;
        loading: boolean;
        success: boolean;
        places: PlaceData[];
    };
};

export type ListNotificationRequest = {
    page?: number;
    keyword?: string;
    place_id?: (number | string)[];
};

export const fetchListNotification = createAsyncThunk(
    'admin/notification',
    async (params: ListNotificationRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListNotification(params);
            const {
                data = [],
                total = 0,
                perPage = Constant.PAGE_SIZE_NEW,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                dispatch(setListNotification({ notification: data, pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListNotification(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const createMyNotification = createAsyncThunk(
    'admin/notification/store',
    async (params: CreateNotificationData, { dispatch, rejectWithValue }) => {
        try {
            let response = await createNotification(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setNewNotification(data));
                return true;
            }
        } catch (error: any) {
            dispatch(setNewNotification(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListNotificationCategory = createAsyncThunk('admin/notification/category', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getCategoryList();
        const { data = [], success } = response.data;
        if (success) {
            dispatch(setCategoryNotification({ categories: data }));
            // dispatch(fetchListCategory());
            return true;
        }
    } catch (error: any) {
        dispatch(setCategoryNotification(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return rejectWithValue(false);
});

export const fetchListSearchPlaceNotification = createAsyncThunk(
    'admin/notification/place/search',
    async (params: SearchPlaceNotificationRequest, { dispatch }) => {
        try {
            const response = await getSearchPlaceInListCharger(params);
            const { data = [], success } = response.data;
            if (success) {
                dispatch(setListSearchPlaceNotification({ places: data }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListSearchPlaceNotification(error));
        }
        return false;
    }
);

export const destroyNotification = createAsyncThunk(
    'admin/notification/:id/delete',
    async (id: string | number, { dispatch, rejectWithValue }) => {
        try {
            const response = await deleteNotification(id);
            dispatch(setDeleteNotification(response));
            return true;
        } catch (error: any) {
            dispatch(setDeleteNotification(error));
            return rejectWithValue(error);
        }
    }
);

export const pushNowNotification = createAsyncThunk(
    '/admin/notification/:id/push-now',
    async (params: { id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await pushNotification(params);
            return response;

        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateItemNotification = createAsyncThunk(
    '/admin/notification/:id/update',
    async (params: { data: NotificationData; id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await updateNotification(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setUpdateNotification(data));
                return true;
            }
        } catch (error: any) {
            dispatch(setUpdateNotification(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);


export const getDetailNotificationData = createAsyncThunk(
    'admin/notification/:id',
    async (params: { id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailNotification(params);
            const { success } = response?.data;
            if (success) {
                dispatch(setDetailNotification(response?.data));
                return success;
            }
        } catch (error: any) {
            dispatch(setDetailNotification(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        notificationData: [],
        register: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        delete: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        listCategory: {
            error: false,
            loading: false,
            success: false,
            categories: [],
        },
        listNotification: {
            error: false,
            loading: false,
            success: false,
            notification: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE_NEW,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
            status: '',
        },
        listSearchPlace: {
            error: false,
            loading: false,
            success: false,
            places: [],
        },
        update: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        detail: {
            success: false,
            error: false,
            messages: '',
            loading: false,
            dataDetail: {} as DetailNotificationData,
            pushAvailable: false,
        },
        pushNotification: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
    } as NotificationState,
    reducers: {
        resetRegisterState: (state: NotificationState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.register = {
                success: false,
                loading: false,
                message: '',
                error: false,
            };
        },
        setNewNotification: (state, action) => {
            state.notificationData = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
        },
        setCategoryNotification: (state: NotificationState, { payload }) => {
            const { categories } = payload;
            state.listCategory.categories = categories;
            state.status = payload?.response?.status;
        },
        setListNotification: (state: NotificationState, { payload }) => {
            const { notification, pagination } = payload;
            state.listNotification.notification = notification;
            state.listNotification.pagination = pagination;
        },
        setListSearchPlaceNotification: (state: NotificationState, { payload }) => {
            const { places } = payload;
            state.listSearchPlace.places = places;
            state.status = payload?.response?.status;
        },
        setDeleteNotification: (state: NotificationState, { payload }) => {
            const { notification } = payload;
            state.listNotification.notification = notification;
            state.listNotification.notification = payload?.response?.status;
        },
        setUpdateNotification: (state, action) => {
            state.notificationData = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
        },
        setDetailNotification: (state: NotificationState, { payload }) => {
            state.detail.dataDetail = payload?.data;
            state.detail.success = payload?.success;
            state.detail.messages = payload?.message;
            state.detail.pushAvailable = payload?.pushAvailable;
            state.status = payload?.response?.status;
        },
        resetPushNotificationState: (state: NotificationState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.pushNotification = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            }
        },
        resetUpdateState: (state: NotificationState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.update = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        resetDeleteState: (state: NotificationState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.delete = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        resetListNotificationState: (state: NotificationState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.update = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListNotification.pending, (state: NotificationState) => {
                state.listNotification.loading = true;
            })
            .addCase(fetchListNotification.rejected, (state: NotificationState) => {
                state.listNotification.loading = false;
                state.listNotification.success = false;
                state.listNotification.error = true;
            })
            .addCase(fetchListNotification.fulfilled, (state: NotificationState) => {
                state.listNotification.loading = false;
                state.listNotification.success = true;
                state.listNotification.error = false;
            })
            .addCase(createMyNotification.pending, (state: NotificationState) => {
                state.register.loading = true;
            })
            .addCase(createMyNotification.rejected, (state: NotificationState, { payload }) => {
                state.register.loading = false;
                state.register.success = false;
                state.register.error = true;
                state.register.message = payload as string | RegisterNotificationData;
            })
            .addCase(createMyNotification.fulfilled, (state: NotificationState) => {
                state.register.loading = false;
                state.register.success = true;
                state.register.error = false;
            })
            .addCase(fetchListNotificationCategory.pending, (state: NotificationState) => {
                state.listCategory.loading = true;
            })
            .addCase(fetchListNotificationCategory.rejected, (state: NotificationState) => {
                state.listCategory.loading = false;
                state.listCategory.success = false;
                state.listCategory.error = true;
            })
            .addCase(fetchListNotificationCategory.fulfilled, (state: NotificationState) => {
                state.listCategory.loading = false;
                state.listCategory.success = true;
                state.listCategory.error = false;
            })
            .addCase(fetchListSearchPlaceNotification.pending, (state: NotificationState) => {
                state.listSearchPlace.loading = true;
            })
            .addCase(fetchListSearchPlaceNotification.rejected, (state: NotificationState) => {
                state.listSearchPlace.loading = false;
                state.listSearchPlace.success = false;
                state.listSearchPlace.error = true;
            })
            .addCase(fetchListSearchPlaceNotification.fulfilled, (state: NotificationState) => {
                state.listSearchPlace.loading = false;
                state.listSearchPlace.success = true;
                state.listSearchPlace.error = false;
            })
            .addCase(destroyNotification.pending, (state: NotificationState) => {
                state.delete.loading = true;
            })
            .addCase(destroyNotification.rejected, (state: NotificationState) => {
                state.delete.loading = false;
                state.delete.success = false;
                state.delete.error = true;
            })
            .addCase(destroyNotification.fulfilled, (state: NotificationState) => {
                state.delete.loading = false;
                state.delete.success = true;
                state.delete.error = false;
            })

            .addCase(pushNowNotification.pending, (state: NotificationState) => {
                state.pushNotification = {
                    loading: true,
                    success: false,
                    error: false,
                    messages: ''
                }

            })
            .addCase(pushNowNotification.rejected, (state: NotificationState, {payload}) => {
                state.pushNotification = {
                    loading: false,
                    success: false,
                    error: true,
                    messages: 'error'
                }
            })
            .addCase(pushNowNotification.fulfilled, (state: NotificationState) => {
                state.pushNotification = {
                    loading: false,
                    success: true,
                    error: false,
                    messages: ''
                }
            })

            .addCase(updateItemNotification.pending, (state: NotificationState) => {
                state.update.loading = true;
            })
            .addCase(updateItemNotification.rejected, (state: NotificationState, { payload }) => {
                state.update.loading = false;
                state.update.success = false;
                state.update.error = true;
                state.update.messages = payload as string | RegisterNotificationData;
            })
            .addCase(updateItemNotification.fulfilled, (state: NotificationState) => {
                state.update.loading = false;
                state.update.success = true;
                state.update.error = false;
            })
            .addCase(getDetailNotificationData.pending, (state: NotificationState) => {
                state.detail.loading = true;
            })
            .addCase(getDetailNotificationData.rejected, (state: NotificationState, { payload }) => {
                state.detail.loading = false;
                state.detail.success = false;
                state.detail.error = true;
                state.detail.messages = payload as string;
            })
            .addCase(getDetailNotificationData.fulfilled, (state: NotificationState) => {
                state.detail.loading = false;
                state.detail.success = true;
                state.detail.error = false;
            });
    },
});

export const {
    setDeleteNotification,
    resetRegisterState,
    setNewNotification,
    setCategoryNotification,
    setListNotification,
    setListSearchPlaceNotification,
    setUpdateNotification,
    setDetailNotification,
    resetUpdateState,
    resetPushNotificationState,
    resetListNotificationState,
    resetDeleteState
} = notificationSlice.actions;
export const notificationSelector = (state: RootState) => state.notification;
