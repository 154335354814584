import 'assets/styles/app.scss';

import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BaseLayout from 'components/layouts/BaseLayout';
import ListPlace from 'pages/Place/List';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { fetchUserData, selectAuth } from './redux/slice/authSlice';
import Loader from './components/layouts/Loader';
import SuccessModal from 'components/layouts/SuccessModal';
const AccountSetting = lazy(() => import('pages/AccountSetting'));
const Login = lazy(() => import('pages/Login/index'));
const ChangePassword = lazy(() => import('pages/Password/ChangePassword'));
const ResetPassword = lazy(() => import('pages/Password/ResetPassword'));
const ForgotPassword = lazy(() => import('pages/Password/ForgotPassword'));
const Chargers = lazy(() => import('pages/Charger'));
const ListCharger = lazy(() => import('pages/Charger/List'));
const AddCharger = lazy(() => import('pages/Charger/Create'));
const EditCharger = lazy(() => import('pages/Charger/Edit'));
const DetailChargers = lazy(() => import('pages/Charger/Detail'));
const ListUser = lazy(() => import('pages/User/ListUser'));
const DetailUser = lazy(() => import('pages/User/DetailUser'));
const Places = lazy(() => import('pages/Place'));
const AddPlace = lazy(() => import('pages/Place/Create'));
const DetailPlaces = lazy(() => import('pages/Place/Detail'));
const EditPlaces = lazy(() => import('pages/Place/Edit'));
const HistoryCharger = lazy(() => import('pages/HistoryCharger'));
const ListAllHistoryCharger = lazy(() => import('pages/HistoryCharger/ListAllHistory'));
const DetailHistoryCharger = lazy(() => import('pages/HistoryCharger/Detail'));
const DetailBookingCancel = lazy(() => import('pages/HistoryCharger/DetailBookingCancel'));
const ListHistory = lazy(() => import('pages/HistoryCharger/ListHistory'));
const Forbidden = lazy(() => import('pages/Forbidden'));
const InternalServer = lazy(() => import('pages/InternalServer'));
const NotFound = lazy(() => import('pages/Error404'));
const BookingList = lazy(() => import('pages/Charger/BookingList'));
const CreateAccount = lazy(() => import('pages/AccountSetting/Create'));
const Home = lazy(() => import('pages/Home/index'));
const Notification = lazy(() => import('pages/Notification'));
const NotificationList = lazy(() => import('pages/Notification/List'));
const AddNotification = lazy(() => import('pages/Notification/Create'));
const DetailNotification = lazy(() => import('pages/Notification/Detail'));
const EditNotification = lazy(() => import('pages/Notification/Edit'));
const Category = lazy(() => import('pages/Category'));
const CategoryList = lazy(() => import('pages/Category/List'));
const Map = lazy(() => import('pages/Map/index'));

const App = () => {
    const { token } = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (token) {
            dispatch(fetchUserData());
        }
    }, [dispatch, token]);

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader force />}>
                <Routes>
                    <Route path='/' element={<BaseLayout />}>
                        <Route index element={<Home />} />
                        <Route path='/chargers' element={<Chargers />}>
                            <Route path='create' element={<AddCharger />} />
                            <Route path=':id/edit' element={<EditCharger />} />
                            <Route path='detail/:id' element={<DetailChargers />} />
                            <Route path='list-chargers/:id' element={<ListCharger />} />
                            <Route path=':id/booking-list/:charger_id' element={<BookingList />} />
                            <Route path='chargers-history/:id' element={<ListHistory />} />
                        </Route>
                        <Route path='/places' element={<Places />}>
                            <Route index element={<ListPlace />} />
                            <Route path='create' element={<AddPlace />} />
                            <Route path='detail/:id' element={<DetailPlaces />} />
                            <Route path=':id/edit' element={<EditPlaces />} />
                        </Route>
                        <Route path='/chargers-history' element={<HistoryCharger />}>
                            <Route index element={<ListAllHistoryCharger />} />
                            <Route path=':id' element={<DetailHistoryCharger />} />
                            <Route path='booking/:id' element={<DetailBookingCancel />} />
                        </Route>
                        <Route path='/users' element={<ListUser />} />
                        <Route path='/users/detail/:id' element={<DetailUser />} />
                        <Route path='/accounts' element={<AccountSetting />}>
                            <Route path='create' element={<CreateAccount />} />
                        </Route>
                        <Route path='/change-password' element={<ChangePassword />} />

                        <Route path='/notification' element={<Notification />}>
                            <Route index element={<NotificationList />} />
                            <Route path='create' element={<AddNotification />} />
                            <Route path='detail/:id' element={<DetailNotification />} />
                            <Route path=':id/edit' element={<EditNotification />} />
                        </Route>
                        <Route path='/category' element={<Category />}>
                            <Route index element={<CategoryList />} />
                        </Route>
                        <Route path='/map' element={<Map />} />
                    </Route>
                    <Route path='/login' element={<Login />} />
                    <Route path='/reset-password' element={<ResetPassword />} />
                    <Route path='/reset-password/:token' element={<ForgotPassword />} />
                    <Route path='/forbidden' element={<Forbidden />} />
                    <Route path='/internal-sever' element={<InternalServer />} />
                    <Route path='/404' element={<NotFound />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
            <SuccessModal />
        </BrowserRouter>
    );
};

export default App;
