import { FormInstance } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { RcFile } from 'antd/lib/upload/interface';
import { first, get, isArray, isEmpty, isNumber, isObject, isString, keysIn } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectAuth } from 'redux/slice/authSlice';
import { Roles } from 'types';
import { UPLOAD_STORAGE } from '../api';
import { Rule } from 'antd/lib/form';
import { timeDayRequired } from '../validators';
import { HolidayType } from '../config/constant';
import moment from "moment";
import Resizer from "react-image-file-resizer";
import {imageUpload} from "../api/place";

/**
 * Scroll to top
 */
export const scrollToTop = (behavior?: ScrollBehavior) =>
    window.scroll({
        top: 0,
        left: 0,
        behavior: behavior,
    });

export const isDevMode = () => {
    return process.env.NODE_ENV === 'development';
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 767);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange();

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return isMobile;
};

export function useIsActiveMenu(): [string[], Dispatch<SetStateAction<string[]>>] {
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const { pathname } = useLocation();

    useEffect(() => {
        const settings = ['/chargers', '/places'];
        if (isMatchPath(settings, pathname)) {
            setOpenKeys(['chargers', 'places']);
        }
    }, [pathname]);

    return [openKeys, setOpenKeys];
}

export const isMatchPath = (paths: string[], pathname: string) => {
    return paths.some((path) => !!matchPath({ path, end: true, caseSensitive: true }, pathname));
};

export const useSelectedMenu = () => {
    const { pathname } = useLocation();

    const places = [
        '/places',
        '/places/create',
        '/places/:id/edit',
        '/places/detail/:id',
        '/chargers/create',
        '/chargers/edit-chargers',
        '/chargers/detail/:id',
        '/chargers/:id/edit',
        '/chargers',
        '/chargers/list-chargers/:id',
        '/chargers/:id/booking-list/:charger_id',
    ];
    const users = ['/users', '/users/detail/:id'];
    const chargersHistory = ['/chargers-history', '/chargers-history/:id'];
    const account = ['/accounts', '/accounts/create'];
    const changePassword = ['/change-password'];
    const notification = ['/notification'];
    const category = ['/category'];
    const map = ['/map'];
    const home = [''];

    let selectedMenus = [];
    switch (true) {
        case isMatchPath(places, pathname):
            selectedMenus = ['places'];
            break;
        case isMatchPath(users, pathname):
            selectedMenus = ['users'];
            break;
        case isMatchPath(chargersHistory, pathname):
            selectedMenus = ['chargers-history'];
            break;
        case isMatchPath(account, pathname):
            selectedMenus = ['accounts'];
            break;
        case isMatchPath(changePassword, pathname):
            selectedMenus = ['change-password'];
            break;
        case isMatchPath(notification, pathname):
            selectedMenus = ['notification'];
            break;
        case isMatchPath(category, pathname):
            selectedMenus = ['category'];
            break;
        case isMatchPath(map, pathname):
            selectedMenus = ['map'];
            break;
        case isMatchPath(home, pathname):
            selectedMenus = [''];
            break;
        default:
            selectedMenus = [pathname];
            break;
    }

    return selectedMenus;
};

export function useFormValidate<S = undefined>(
    form: FormInstance,
    keyMultiple?: string[]
): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
    const [messageErrors, setMessageErrors] = useState<S>();

    useEffect(() => {
        if (!isEmpty(messageErrors) && isObject(messageErrors)) {
            const firstKeyError = first(keysIn(messageErrors));
            isString(firstKeyError) && form.scrollToField(firstKeyError.replace(/\.[0-9]/g, ''), { behavior: 'smooth' });
            form.setFields(
                keysIn(messageErrors).map((key) => {
                    let name: NamePath = first(key?.split(/\.+/g)) ?? key.replace(/\.\d/g, '');
                    if (keyMultiple?.includes(name) && key.split(/\.+/g)?.length > 0) {
                        name = key.split(/\.+/g)?.map((val) => (/\d+/g.test(val) ? Number(val) : val));
                    }
                    return {
                        name,
                        errors: get(messageErrors, key),
                        touched: true,
                    };
                })
            );
        }
    }, [form, messageErrors]);

    return [messageErrors, setMessageErrors];
}

const resizeFile = (file: File) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(file, 400, 300, "JPEG", 100, 0, (uri) => {
            resolve(uri);
        },'file');
    });

export const beforeResizeImage = (file: File, formInstance: FormInstance, namePath: NamePath): Promise<boolean> => {
    return new Promise((resolve) => {
        const isJpgOrPng = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
        if (!isJpgOrPng) {
            formInstance.setFields([
                {
                    name: namePath,
                    errors: ['プロフィール画像の形式はpng、 jpg、 jpegです。'],
                    touched: true,
                },
            ]);
            return Promise.reject('プロフィール画像の形式はpng、 jpg、 jpegです。');
        }

        // const isLt2M = file.size / 1024 / 1024 <= 10;
        // if (!isLt2M) {
        //     formInstance.setFields([
        //         {
        //             name: namePath,
        //             errors: ['プロフィール画像の最大サイズは10MBです。'],
        //             touched: true,
        //         },
        //     ]);
        //     return Promise.reject('プロフィール画像の最大サイズは10MBです。');
        // }

        resolve(true);
        formInstance.setFields([
            {
                name: namePath,
                errors: [],
                touched: true,
            },
        ]);
        return Promise.resolve(true);
    });
};

export const beforeUploadImage = (file: RcFile, formInstance: FormInstance, namePath: NamePath): Promise<boolean> => {
    return new Promise((resolve) => {
        if (namePath === 'certificate_file') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', (event: any) => {
                const image = document.createElement('img');
                image.src = event.target.result;
                image.addEventListener('load', () => {
                    resolve(true);
                    return Promise.resolve(true);
                });
            });
        } else {
            resolve(true);
            return Promise.resolve(true);
        }
    });
};

export const customRequestUpload = (options: any) => {
    const { onSuccess, onError, file } = options;
    resizeFile(file).then(resFile=>{
        const fmData = new FormData();
        fmData.append("file", resFile as File);

        imageUpload({formData: fmData}).then(res=>{
            onSuccess(res);
        }).catch(err=>{
            onError(err);
        });
    })
};

export const mbTrim = (value?: any) => {
    if (isNumber(value) || (!isEmpty(value) && !isString(value))) {
        return value;
    }
    if (isEmpty(value)) {
        return '';
    }
    return value.replace(/^[\s　]+/u, '').replace(/[\s　]+$/u, '');
};

export const handleFormData = (params: object) => {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
        const value = get(params, key);
        if (isArray(value)) {
            [...value].forEach((val, number) => {
                if (isObject(val)) {
                    Object.keys(val).forEach((item) => {
                        if (!isEmpty(get(val, item))) {
                            formData.append(`${key}[${number}][${item}]`, mbTrim(get(val, item)));
                        }
                    });
                } else {
                    formData.append(`${key}[]`, mbTrim(val));
                }
            });
        } else {
            formData.append(key, mbTrim(value));
        }
        if (value === undefined) {
            formData.delete(key);
        }
    });
    return formData;
};

export function useForbidden(roles: Roles[], redirectTo?: string, condition?: boolean) {
    const { role } = useAppSelector(selectAuth);
    const navigate = useNavigate();

    useEffect(() => {
        if ((condition ?? true) && !isValidRoles(role, roles)) {
            navigate(redirectTo ?? '/forbidden', { replace: true });
        }
    }, [navigate, role, roles, redirectTo, condition]);
}

export function useRedirectStatusError(value?: number, action?: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        switch (value) {
            case 500:
                navigate('/internal-sever', { replace: true });
                break;
            case 429:
                navigate('/internal-sever', { replace: true });
                break;
            case 404:
                navigate('/404', { replace: true });
                dispatch(action);
                break;
            case 403:
                navigate('/forbidden', { replace: true });
                dispatch(action);
                break;
            // case 400:
            //     navigate('/400', { replace: true });
            //     dispatch(action);
            //     break;
            default:
                break;
        }
    }, [navigate, value, dispatch, action]);
}

export function isValidRoles(userRoles: Roles[], allRoles: Roles[]): boolean {
    return userRoles.some((value) => allRoles.includes(value));
}

export function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
}

export const downloadCsv = (csvData: string) => {
    const blob = new Blob([csvData], {
        type: 'text/csv;',
    });
    const fileName = `Terra_${moment().format('YYYYMMDD_HHmm')}.csv`;
    const blobUrl = window.URL.createObjectURL(blob);
    const csvLink = document.createElement('a');
    csvLink.href = blobUrl;
    csvLink.download = fileName;
    document.body.appendChild(csvLink);
    csvLink.click();
    document.body.removeChild(csvLink);
    window.URL.revokeObjectURL(blobUrl);
};

export const checkPath = (path: string) => {
    return path?.replace(`${UPLOAD_STORAGE}`, '') ? path : '';
};

export const timeCheckRules: (dayCheck: boolean, day: HolidayType) => Rule[] = (dayCheck, day) => [
    dayCheck ? timeDayRequired('時と分をご入力ください。', '終了日は開始日の後の日付をご入力ください。', day) : {},
];

export const timeCheckDayRules: (dayCheck: number, day: HolidayType) => Rule[] = (dayCheck, day) => [
    dayCheck==2 ? timeDayRequired('時と分をご入力ください。', '終了日は開始日の後の日付をご入力ください。', day) : {},
];
