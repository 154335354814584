import { getAxiosClientWithToken } from 'api';
import { handleFormData } from 'lib/utils';
import { MyPlaceData } from 'types';
import { ListPlaceRequest } from 'redux/slice/listPlaceSlice';

export const getListPlace = (params: ListPlaceRequest) => {
    return getAxiosClientWithToken().get('admin/place', { params });
};

export const createPlace = (params: MyPlaceData) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/place/store`, formData);
};

export const getPrefecture = (params: { zipcode?: number }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/zipcode', { params });
};

export const editPlace = (params: { field: MyPlaceData; id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params.field);
    return restClient.post(`/admin/place/${params.id}/update`, formData);
};

export const getDetailPlace = (params: { id?: Number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/place/${params.id}`);
};

export const getPlaceType = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('admin/place-type');
};

export const getPlaceStatistic = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('admin/place/statistic');
};

export const getAmountOfElectricity = (params: { day_search?: string; latitude?: string; longitude?: string }, id?: Number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/place/${id}/power-usage`, { params });
};

export const getPinPlace = (params: { latitude?: string; longitude?: string }, id?: Number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/map/pin-place/${id}`, { params });
};

export const stopPlace = (id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/place/${id}/stop`);
};

export const openPlace = (id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/place/${id}/open`);
};

export const pinPlace = (params: { latitude : number | string, longitude : number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('admin/map/pin-place',{ params });
};

export const imageUpload = (params: { formData: FormData }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/multiple-image-upload`, params.formData);
};