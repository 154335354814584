import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import {
    createPlace,
    getDetailPlace,
    getPrefecture,
    editPlace,
    getPlaceType,
    getPlaceStatistic,
    getAmountOfElectricity,
    getPinPlace,
    stopPlace,
    openPlace,
    pinPlace,
} from 'api/place';
import { RootState } from 'redux/store';
import { DetailPlaceData, MyPlaceData, ZipCode } from 'types';
import { Constant } from 'config/constant';

export type RegisterPlaceValidate = {
    name: string | string[];
    phone: string | string[];
    email: string | string[];
    place_type_id: string | string[];
    admin_id: string | string[];
    zip_code: string | string[];
    prefecture: string | string[];
    city: string | string[];
    address: string | string[];
    latitude: string | string[];
    longitude: string | string[];
    monthly_fee: string | string[];
    monthly_fee_amount: string | string[];
    electricity_capacity: string | string[];
    note: string | string[];
    building_name: string;
};

export type ZipcodeValidate = {
    zipcode: number;
    pref: string;
    state_name: string;
    components: string[];
    address: string;
};

export type PlaceType = {
    id: number;
    name: string;
};

export type Place = {
    total: number;
    detail: {};
};
export type Charger = {
    total: number;
    detail: {};
};
export type ChargerUser = {
    detail: {
        '15_days_left': number;
        '30_days_left': number;
        '60_days_left': number;
        '6_months_not_use': number;
    };
};
export type ChargerStatisticType = {
    detail: {};
};

export type PlaceStatistic = {
    place: Place;
    charger: Charger;
    charger_used: ChargerUser;
    charger_type: ChargerStatisticType;
};

export type PowerUsage = {
    detail: PowerUsageDetail[];
    total_power: Number;
};

export type ChargerPin = {
    chargers_total: Number;
    latitude?: string;
    longitude?: string;
};

export type PlacePin = {
    chargers_total: number;
    latitude: number;
    longitude: number;
};

export type PowerUsageDetail = {
    hour: string;
    value: Number | string;
};

export type PlaceData = {
    status: number;
    myPlaceData: MyPlaceData[];
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | RegisterPlaceValidate;
    };
    zipCode: {
        success: boolean;
        message: string | ZipcodeValidate;
        ZipCode: ZipCode;
        loading: boolean;
        error: boolean;
    };
    detailPlace: {
        success: boolean;
        message: string | RegisterPlaceValidate;
        place: DetailPlaceData;
        loading: boolean;
        error: boolean;
    };
    update: {
        success: boolean;
        error: boolean;
        messages: string | RegisterPlaceValidate;
        loading: boolean;
    };
    stopPlace: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    openPlace: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    masterPlaceType: {
        error: boolean;
        loading: boolean;
        success: boolean;
        data: PlaceType[];
    };
    placeStatistic: {
        error: boolean;
        success: boolean;
        loading: boolean;
        message: string;
        data: PlaceStatistic;
    };
    powerUsage: {
        error: boolean;
        success: boolean;
        loading: boolean;
        message: string;
        data: PowerUsage;
    };
    pinPlace: {
        error: boolean;
        success: boolean;
        loading: boolean;
        message: string;
        data: ChargerPin[];
    };
    pinPlaces: {
        error: boolean;
        success: boolean;
        loading: boolean;
        message: string;
        data: PlacePin[];
    };
};

export const createMyPlace = createAsyncThunk('admin/place/store', async (params: MyPlaceData, { dispatch, rejectWithValue }) => {
    try {
        params.latitude = params.latitude.toString().replace(',', '.');
        params.longitude = params.longitude.toString().replace(',', '.');
        params.electricity_capacity = params.electricity_capacity.toString().replace(',', '.');
        if (Number(params.monthly_fee)) {
            params.monthly_fee_amount = Number(params.monthly_fee_amount.toString().replace(',', '.'));
        }
        let response = await createPlace(params);
        const { data = {}, success } = response.data;
        if (success) {
            dispatch(setNewPlace(data));
            return true;
        }
    } catch (error: any) {
        dispatch(setNewPlace(error));
        return rejectWithValue(getErrorMessage(error));
    }
});

export const getDetailPlaceData = createAsyncThunk(
    'admin/place/:id',
    async (params: { id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailPlace(params);
            const { success } = response.data;
            if (success) {
                dispatch(setDetailPlace(response.data));
                return success;
            }
        } catch (error: any) {
            dispatch(setDetailPlace(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getPrefectureData = createAsyncThunk('admin/zipcode', async (params: { zipcode?: number }, { dispatch, rejectWithValue }) => {
    try {
        let response = await getPrefecture(params);
        const { data = null, success, message = null } = response.data;
        if (success && data) {
            dispatch(setPrefecture(data));
            return true;
        }
        return rejectWithValue(message);
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const editMyPlace = createAsyncThunk(
    '/admin/place/:id/update',
    async (params: { field: MyPlaceData; id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await editPlace(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setEditPlace(data));
                return true;
            }
        } catch (error: any) {
            dispatch(setEditPlace(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchPlaceType = createAsyncThunk('admin/place-type', async (data, { dispatch }) => {
    try {
        const response = await getPlaceType();
        const { data = [], success } = response.data;
        if (success) {
            dispatch(setPlaceType(data));
            return true;
        }
        return false;
    } catch (error: any) {}
    return false;
});

export const fetchPlaceStatistic = createAsyncThunk('admin/place/statistic', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getPlaceStatistic();
        const { data } = response.data;
        dispatch(setPlaceStatistic(data));
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const fetchAmountOfElectricity = createAsyncThunk(
    'admin/place/:id/power-usage',
    async (params: { day_search?: string; latitude?: string; longitude?: string; id?: Number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getAmountOfElectricity(
                { day_search: params.day_search, latitude: params.latitude, longitude: params.longitude },
                params.id
            );
            const { data } = response.data;
            dispatch(setPlacePowerUsage(data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchPinPlace = createAsyncThunk(
    'admin/map/pin-place/:id',
    async (params: { latitude?: string; longitude?: string; id?: Number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getPinPlace({ latitude: params.latitude, longitude: params.longitude }, params.id);
            const { data } = response.data;
            dispatch(setPlacePinMap(data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchPinPlaces = createAsyncThunk(
    'admin/map/pin-place',
    async (params: { latitude: string | number; longitude: string | number }, { dispatch, rejectWithValue }) => {
        try {
            const response = await pinPlace(params);
            const { data } = response.data;
            dispatch(setPlacesPinMap(data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchStopPlace = createAsyncThunk(
    'admin/place/:id/stop',
    async (params: { id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await stopPlace(params.id);

            dispatch(setStopPlace(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchOpenPlace = createAsyncThunk(
    'admin/place/:id/open',
    async (params: { id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await openPlace(params.id);

            dispatch(setOpenPlace(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const placeSlice = createSlice({
    name: 'places',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        myPlaceData: [],
        register: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        zipCode: {
            success: false,
            message: '',
            ZipCode: {} as ZipCode,
            loading: false,
            error: false,
        },
        detailPlace: {
            success: false,
            message: '',
            place: {} as DetailPlaceData,
            loading: false,
            error: false,
        },
        update: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        stopPlace: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        openPlace: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        masterPlaceType: {
            error: false,
            loading: false,
            success: false,
            data: [],
        },
        placeStatistic: {
            error: false,
            loading: false,
            success: false,
            message: '',
            data: {} as PlaceStatistic,
        },
        powerUsage: {
            error: false,
            success: false,
            loading: false,
            message: '',
            data: {} as PowerUsage,
        },
        pinPlace: {
            error: false,
            success: false,
            loading: false,
            message: '',
            data: [] as ChargerPin[],
        },
        pinPlaces: {
            error: false,
            success: false,
            loading: false,
            message: '',
            data: [] as PlacePin[],
        },
    } as PlaceData,
    reducers: {
        resetUpdateState: (state: PlaceData) => {
            state.status = Constant.DEFAULT_STATUS;
            state.update = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        resetRegisterState: (state: PlaceData) => {
            state.status = Constant.DEFAULT_STATUS;
            state.register = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetGetPrefState: (state: PlaceData) => {
            state.status = 0;
            state.zipCode = {
                success: false,
                message: '',
                ZipCode: {} as ZipCode,
                loading: false,
                error: false,
            };
        },
        resetGetPrefErrorState: (state: PlaceData) => {
            state.status = Constant.DEFAULT_STATUS;
            state.zipCode = {
                ...state.zipCode,
                message: '',
                error: false,
            };
        },
        resetPinPlaceData: (state: PlaceData) => {
            state.pinPlaces.data = [];
        },
        setNewPlace: (state, action) => {
            state.myPlaceData = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
        },
        setEditPlace: (state, action) => {
            state.myPlaceData = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
        },
        setPrefecture: (state: PlaceData, { payload }) => {
            state.zipCode.ZipCode = payload;
            state.status = payload?.response?.status;
        },
        setStopPlace: (state: PlaceData, { payload }) => {
            state.stopPlace.success = payload?.success;
            state.status = payload?.code;
        },
        resetStopPlace: (state: PlaceData) => {
            state.status = Constant.DEFAULT_STATUS;
            state.stopPlace = {
                error: false,
                success: false,
                messages: '',
                loading: false,
            };
        },
        setOpenPlace: (state: PlaceData, { payload }) => {
            state.openPlace.success = payload?.success;
            state.status = payload?.code;
        },
        resetOpenPlace: (state: PlaceData) => {
            state.status = Constant.DEFAULT_STATUS;
            state.openPlace = {
                error: false,
                success: false,
                messages: '',
                loading: false,
            };
        },
        resetDetailPlace: (state: PlaceData) => {
            state.status = Constant.DEFAULT_STATUS;
            state.detailPlace = {
                success: false,
                message: {} as RegisterPlaceValidate,
                place: {} as DetailPlaceData,
                loading: false,
                error: false,
            }
        },
        setDetailPlace: (state: PlaceData, { payload }) => {
            state.detailPlace.place = payload?.data;
            state.detailPlace.success = payload?.success;
            state.detailPlace.message = payload?.message;
            state.status = payload?.response?.status;
        },
        setPlaceType: (state: PlaceData, { payload }) => {
            state.masterPlaceType.data = payload;
        },
        setPlaceStatistic: (state: PlaceData, { payload }) => {
            state.placeStatistic.data = payload;
        },
        setPlacePowerUsage: (state: PlaceData, { payload }) => {
            state.powerUsage.success = payload?.success;
            state.powerUsage.message = payload?.message;
            state.powerUsage.data = payload;
        },
        setPlacePinMap: (state: PlaceData, { payload }) => {
            state.pinPlace.success = payload?.success;
            state.pinPlace.message = payload?.message;
            state.pinPlace.data = payload;
        },
        setPlacesPinMap: (state: PlaceData, { payload }) => {
            state.pinPlaces.success = payload?.success;
            state.pinPlaces.message = payload?.message;
            state.pinPlaces.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createMyPlace.pending, (state: PlaceData, action) => {
                state.register.loading = true;
            })
            .addCase(createMyPlace.rejected, (state: PlaceData, { payload }) => {
                state.register.loading = false;
                state.register.success = false;
                state.register.error = true;
                state.register.message = payload as string | RegisterPlaceValidate;
            })
            .addCase(createMyPlace.fulfilled, (state: PlaceData, action) => {
                state.register.loading = false;
                state.register.success = true;
                state.register.error = false;
            })
            .addCase(editMyPlace.pending, (state: PlaceData, action) => {
                state.update.loading = true;
            })
            .addCase(editMyPlace.rejected, (state: PlaceData, { payload }) => {
                state.update.loading = false;
                state.update.success = false;
                state.update.error = true;
                state.update.messages = payload as string | RegisterPlaceValidate;
            })
            .addCase(editMyPlace.fulfilled, (state: PlaceData, action) => {
                state.update.loading = false;
                state.update.success = true;
                state.update.error = false;
            })
            .addCase(getPrefectureData.pending, (state: PlaceData) => {
                state.zipCode.loading = true;
            })
            .addCase(getPrefectureData.rejected, (state: PlaceData, { payload }) => {
                state.zipCode.ZipCode = {} as ZipCode;
                state.zipCode.loading = false;
                state.zipCode.success = false;
                state.zipCode.error = true;
                state.zipCode.message = payload as string | ZipcodeValidate;
            })
            .addCase(getPrefectureData.fulfilled, (state: PlaceData, action) => {
                state.zipCode.loading = false;
                state.zipCode.success = true;
                state.zipCode.error = false;
                state.zipCode.message = '';
            })
            .addCase(getDetailPlaceData.pending, (state: PlaceData) => {
                state.detailPlace.loading = true;
            })
            .addCase(getDetailPlaceData.rejected, (state: PlaceData, { payload }) => {
                state.detailPlace.loading = false;
                state.detailPlace.success = false;
                state.detailPlace.error = true;
                state.detailPlace.message = payload as string;
            })
            .addCase(getDetailPlaceData.fulfilled, (state: PlaceData, action) => {
                state.detailPlace.loading = false;
                state.detailPlace.success = true;
                state.detailPlace.error = false;
            })
            .addCase(fetchPlaceType.pending, (state: PlaceData) => {
                state.masterPlaceType.loading = true;
            })
            .addCase(fetchPlaceType.rejected, (state: PlaceData, { payload }) => {
                state.masterPlaceType.loading = false;
                state.masterPlaceType.success = false;
                state.masterPlaceType.error = true;
            })
            .addCase(fetchPlaceType.fulfilled, (state: PlaceData, action) => {
                state.masterPlaceType.loading = false;
                state.masterPlaceType.success = true;
                state.masterPlaceType.error = false;
            })
            .addCase(fetchPlaceStatistic.pending, (state: PlaceData) => {
                state.placeStatistic.loading = true;
            })
            .addCase(fetchPlaceStatistic.rejected, (state: PlaceData) => {
                state.placeStatistic.loading = false;
                state.placeStatistic.success = false;
                state.placeStatistic.error = true;
            })
            .addCase(fetchPlaceStatistic.fulfilled, (state: PlaceData) => {
                state.placeStatistic.loading = false;
                state.placeStatistic.success = true;
                state.placeStatistic.error = false;
            })
            .addCase(fetchAmountOfElectricity.pending, (state: PlaceData) => {
                state.powerUsage.loading = true;
            })
            .addCase(fetchAmountOfElectricity.rejected, (state: PlaceData) => {
                state.powerUsage.loading = false;
                state.powerUsage.success = false;
                state.powerUsage.error = true;
            })
            .addCase(fetchAmountOfElectricity.fulfilled, (state: PlaceData) => {
                state.powerUsage.loading = false;
                state.powerUsage.success = true;
                state.powerUsage.error = false;
            })
            .addCase(fetchPinPlace.pending, (state: PlaceData) => {
                state.pinPlace.loading = true;
            })
            .addCase(fetchPinPlace.rejected, (state: PlaceData) => {
                state.pinPlace.loading = false;
                state.pinPlace.success = false;
                state.pinPlace.error = true;
            })
            .addCase(fetchPinPlace.fulfilled, (state: PlaceData) => {
                state.pinPlace.loading = false;
                state.pinPlace.success = true;
                state.pinPlace.error = false;
            })
            .addCase(fetchPinPlaces.pending, (state: PlaceData) => {
                state.pinPlaces.loading = true;
            })
            .addCase(fetchPinPlaces.rejected, (state: PlaceData) => {
                state.pinPlaces.loading = false;
                state.pinPlaces.success = false;
                state.pinPlaces.error = true;
            })
            .addCase(fetchPinPlaces.fulfilled, (state: PlaceData) => {
                state.pinPlaces.loading = false;
                state.pinPlaces.success = true;
                state.pinPlaces.error = false;
            })
            .addCase(fetchOpenPlace.pending, (state: PlaceData) => {
                state.openPlace.loading = true;
            })
            .addCase(fetchOpenPlace.rejected, (state: PlaceData) => {
                state.openPlace.loading = false;
                state.openPlace.success = false;
                state.openPlace.error = true;
            })
            .addCase(fetchOpenPlace.fulfilled, (state: PlaceData) => {
                state.openPlace.loading = false;
                state.openPlace.success = true;
                state.openPlace.error = false;
            })
            .addCase(fetchStopPlace.pending, (state: PlaceData) => {
                state.stopPlace.loading = true;
            })
            .addCase(fetchStopPlace.rejected, (state: PlaceData) => {
                state.stopPlace.loading = false;
                state.stopPlace.success = false;
                state.stopPlace.error = true;
            })
            .addCase(fetchStopPlace.fulfilled, (state: PlaceData) => {
                state.stopPlace.loading = false;
                state.stopPlace.success = true;
                state.stopPlace.error = false;
            });
    },
});

export const {
    setNewPlace,
    setPrefecture,
    resetUpdateState,
    resetRegisterState,
    setEditPlace,
    setDetailPlace,
    resetGetPrefState,
    resetGetPrefErrorState,
    setPlaceType,
    setPlaceStatistic,
    setPlacePowerUsage,
    setPlacePinMap,
    setOpenPlace,
    setStopPlace,
    resetStopPlace,
    resetOpenPlace,
    setPlacesPinMap,
    resetPinPlaceData,
    resetDetailPlace
} = placeSlice.actions;
export const placeSelector = (state: RootState) => state.places;
